import ScrollToTop from "./components/atoms/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { hideConfirm, showConfirm } from "./reducer/confirmSlice";
import { hideAlert, showAlert } from "./reducer/alertSlice";
import { showPolicy, hidePolicy } from "./reducer/policySlice";
import { Button, Modal } from "react-bootstrap";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import ApplicationComplete from "./components/pages/application/ApplicationComplete";
import styled from "styled-components";
import mixins, { media } from "./styles/mixins";
import Application from "./components/pages/application/Application";
import ActivityForm from "./components/pages/activity/ActivityForm";
import api from "./utils/api";
import Privacy from "./components/pages/policy/Privacy";
import { useEffect } from "react";
import { getCookie, setCookie } from "./utils/utils";
import Error from "./components/organisms/Error";
import PageTemplate from "./components/templates/PageTemplate";
import useCheckLogin from "./hooks/useCheckLogin";
import CustomModal from "./components/organisms/Modal";

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;

    &::before {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
        height: 342px;
        background: linear-gradient(0deg, rgba(252, 253, 253, 0.2) 0%, rgba(41, 102, 152, 0.2) 100%);
        z-index: -1;
    }

    ${media.mobile`
        &::before {
            height: 170px;
        }
    `}
`;

const BtnPrivacyPolicy = styled.div`
    width: 100%;
    height: 100px;
    background-color: #333333;
    cursor: pointer;
    ${mixins.fontStyle("20px", "600", "100px")}
    text-decoration: underline;
    text-align: center;
    color: #fff;
    

    ${media.mobile`
        height: 60px;
        ${mixins.fontStyle("14px", "600", "60px")}
    `}
`;

function App() {
    const location = useLocation();
    const confirm = useSelector(state => state.confirm);
    const alert = useSelector(state => state.alert);
    const policy = useSelector(state => state.policy);
    const checkLogin = useCheckLogin();
    const dispatch = useDispatch();

    api.defaults.withCredentials = true;

    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            console = window.console || {};
            console.log = function no_console() {};
            console.warn = function no_console() {};
            console.error = function () {};
        }

        // 신청 URL 정보 추출 (/문자 제거)
        const idx = location.pathname?.split("/application/")[1]?.replace("\/", "");
        // 신청 페이지일 때만
        if (idx && (!idx.includes("complete") && !idx.includes("modify") && !idx.includes("activity"))) {
            // base64 디코딩
            const idxParams = new URLSearchParams("?" + window.atob(idx));
            // eventIdx, eventScheduleIdx 추출
            const eventIdx = idxParams.get("eventIdx");
            const scheduleIdx = idxParams.get("scheduleIdx");
            if (eventIdx && scheduleIdx) {
                // 쿠키에 저장
                setCookie('eventPath', location.pathname?.split("/application/")[1]);
            }
        }
    },[])

    useEffect(() => {
        console.log("checkLogin", checkLogin);
        
        // Add a response interceptor
        api.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            // 500 에러 발생 시
            if (error?.response?.status == 500) {
                dispatch(
                    showAlert({
                        msg: "서버 오류로 인해 화면을 불러올 수 없습니다.\n잠시 후 다시 시도해 주세요.\n문제가 계속 발생하면\n담당자에게 문의해 주시기 바랍니다.",
                        btnText: "닫기",
                    })
                );
            }
            return Promise.reject(error);
        });
    }, [checkLogin])

    if (checkLogin) {
        return (
        <Wrap className="App">
            <ScrollToTop/>
            <Routes>
                {
                    getCookie('eventPath') 
                    ?
                    <Route path="/" element={<Navigate to={"/application/"+getCookie('eventPath')}/>} />
                    :
                    <Route path="/" element={<PageTemplate><Error error={false} msg="페이지를 찾을 수 없습니다."/></PageTemplate>} />
                }
                <Route path="*" element={<PageTemplate><Error error={false} msg="페이지를 찾을 수 없습니다."/></PageTemplate>} />

                {/* page */}
                {/* activity */}
                <Route path="activity">
                    {/* 액티비티 선택 */}
                    <Route path="form/:scheduleIdx" element={<ActivityForm />}/>
                </Route>
                {/* application */}
                <Route path="application">
                    {/* 신청 기간, 신청 가능 고객 조회 */}
                    <Route path=":idx/:pageType?" element={<Application/>} />
                    {/* 신청 완료 */}
                    <Route path="complete/:applicationIdx/:scheduleIdx" element={<ApplicationComplete/>} />
                </Route>

                <Route path="policy">
                    <Route path="privacy" element={
                        <PageTemplate>
                            <Privacy/>
                        </PageTemplate>
                    } />
                </Route>
            </Routes>
    
            <Modal show={confirm.value} centered size="sm" 
                    backdrop="static"
                    restoreFocus={false}
                    onHide={() => {
                        dispatch(hideConfirm())}
                    }
                >
                <Modal.Body className="text-center" style={{lineHeight: 1.2}}>
                    {confirm.msg}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {dispatch(hideConfirm())}}>
                        취소
                    </Button>
                    <Button type="button" variant="primary" onClick={() => {
                        confirm.func();
                    }}>
                        {confirm.btnText}
                    </Button>
                </Modal.Footer>
            </Modal>
            
            <Modal show={alert.value} centered
                restoreFocus={false}
                onHide={() => {
                    alert.func();
                    dispatch(hideAlert())
                }}
            >
                <Modal.Body className="text-center" style={{lineHeight: 1.2}}>
                    {
                        alert.msg?.split("\n")?.map((el, idx) => {
                            return (
                                <p key={`msg_${idx}`}>
                                    {el}
                                </p>
                            )
                        })
                    }
                    {/* {alert.msg} */}
                </Modal.Body>
                <Modal.Footer>
                    <Button type="button" variant="primary"
                        onClick={() => {
                            alert.func();
                            dispatch(hideAlert())
                        }}>
                        {alert.btnText}
                    </Button>
                </Modal.Footer>
            </Modal>

            <CustomModal title="개인정보 처리방침" show={policy.value} content={<Privacy />} hide={() => {
                dispatch(hidePolicy());
            }} />
    
            {
            location.pathname != "/policy/privacy" &&
            <BtnPrivacyPolicy onClick={() => {
                dispatch(showPolicy());
            }}>
                {/* <Link to={"/policy/privacy"}>
                </Link> */}
                개인정보 처리방침
            </BtnPrivacyPolicy>
            }
        </Wrap>
        );
    }
}

export default App;
