import styled from "styled-components"
import Modal from "./Modal"
import { useEffect, useState } from "react"
import mixins, { media } from "../../styles/mixins";
import { getS3Url } from "../../utils/utils";

const Wrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end; 
    ${mixins.fontStyle("18px", "600", "24.52px", "-0.03em")};
    color: #858585;
    gap: 10px 0;
    flex: 1 1;

    ${media.mobile`
        flex-direction: column;
        ${mixins.fontStyle("16px", "600", "21.79px", "-0.03em")};
        align-self: flex-end;
    `}
`;

const ShowDetailBtn = styled.button`
    border: 0;
    background-color: transparent;
    ${mixins.fontStyle("18px", "600", "24.52px")}
    color: #06315B;

    ${media.mobile`
        ${mixins.fontStyle("14px", "600", "19.07px")};
        padding: 0;
    `}
`
export default function AgreeTermsList({title, contentImgIdx, content}) {
    const [showModal, setShowModal] = useState(false);
    const [imgSrc, setImgSrc] = useState();

    const setImg = async (idx) => {
        setImgSrc(await getS3Url(contentImgIdx));
    }
    useEffect(() => {
        if (contentImgIdx) {
            setImg();
        }
    },[])
    return (
        <>
            <Wrap>

                <ShowDetailBtn className="btn-detail-btn" type="button" onClick={() => {
                    setShowModal(true);
                }}>자세히 보기&gt;</ShowDetailBtn>
            </Wrap>
            <Modal title={title}  content={<img src={imgSrc} />} hide={()=>{setShowModal(false)}} show={showModal} />
        </>
    )
}