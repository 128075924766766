import useDeviceSize from "../../hooks/useDeviceSize";
import FormTable from "./FormTable";

export default function FixedFormTable({children, ...props}) {
    const { isMobile } = useDeviceSize();
    return (
        <FormTable>
            <colgroup>
            {
                isMobile
                ?
                <>
                <col style={{width: '140px'}}/>
                <col />
                </>
                :
                <col style={{width: "370px"}}/>
            }
            </colgroup>
            
            {children}
        </FormTable>
    )
}