import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useScheduleQuery } from "../../../hooks/api/useSchedule";
import PageTemplate from "../../templates/PageTemplate";
import Error from "../../organisms/Error";
import ApplicationForm from "./ApplicationForm";
import ActivityForm from "../activity/ActivityForm";
import { useEffect } from "react";
import Loading from "../../organisms/Loading";

const Application = () => {
	const { idx, pageType } = useParams();
    const idxParams = new URLSearchParams("?" + window.atob(idx));
	const eventIdx = idxParams.get("eventIdx");
	const scheduleIdx = idxParams.get("scheduleIdx");
    const navigate = useNavigate();
    
	const customersInfo = useSelector(state => state.customers).value;
	const scheduleQuery = useScheduleQuery(scheduleIdx, customersInfo[0]?customersInfo[0].uid:"");

    useEffect(() => {
        console.log("eventIdx:", eventIdx , "scheduleIdx: ", scheduleIdx);
    }, [])

    useEffect(() => {
        if (scheduleQuery.data) {
            // document.title = `${window.location.hostname == "localhost" ? "(local) " : ""}${scheduleQuery.data?.title} - ${scheduleQuery.data?.order}차수`;
            // TODO: 차수 추가
            document.title = `${window.location.hostname == "localhost" ? "(local) " : ""}${scheduleQuery.data?.title}`;
        }
    },[scheduleQuery.data]);

    useEffect(() => {
        scheduleQuery.refetch();
    }, [pageType]);

    if (scheduleQuery.isLoading) {
        return (
            <PageTemplate title="">
                <Loading layer={true}/>
            </PageTemplate>
        )
    }

    {/* 신청기간 외 화면 */}
    if (scheduleQuery.isError) {
        if (scheduleQuery.error.response) {
            const title = scheduleQuery.error.response.data.data?.title ? scheduleQuery.error.response.data.data?.title : "";
            const order = scheduleQuery.error.response.data.data?.order ? scheduleQuery.error.response.data.data?.order : ""
            return (
                // TODO: 차수 추가
                // <PageTemplate title={(title && order) ? `${title} - ${order} 차수` : ""}>
                <PageTemplate title={(title && order) ? `${title}` : ""}>
                    <Error msg={scheduleQuery.error?.response.data?.msg?.ko ? scheduleQuery.error?.response.data.msg.ko.replace("/\n/g", <br/>) : "오류가 발생했습니다."}/>
                </PageTemplate>
            )
        } else {
            return (
                <PageTemplate>
                    <Error msg={"오류가 발생했습니다."}/>
                </PageTemplate>
            )
        }
    }

    const getPage = () => {
        const applicationIdx = scheduleQuery.data.application_idx;
        console.log("pageType:", pageType, "applicationIdx:", applicationIdx);
        // 1-1. 신청 정보 있는 경우
        if (applicationIdx) {
            // 2-1. 고객 정보 수정페이지
            if (pageType === "modify") {
                return (
                    <ApplicationForm 
                        target={scheduleQuery.data.target} 
                        eventIdx={eventIdx}
                        scheduleIdx={scheduleIdx}
                        applicationIdx={applicationIdx}
                        urlPath={idx}
                    />
                )
            }

            // 2-2. 액티비티 신청 페이지
            if (pageType === "activity") {
                return (
                    <ActivityForm 
                        scheduleIdx={scheduleIdx} 
                        applicationIdx={applicationIdx}
                        target={scheduleQuery.data.target}
                        urlPath={idx}
                    />
                )
            }

            // 2-3. 신청 완료 페이지
            navigate("/application/complete/" + applicationIdx + "/" + scheduleIdx)
        } 
        // 1-2. 신청 정보 없는 경우
        else {
            return (
                <ApplicationForm 
                    target={scheduleQuery.data.target} 
                    eventIdx={eventIdx}
                    scheduleIdx={scheduleIdx}
                    urlPath={idx}
                />
            )
        }
    }

    if (scheduleQuery.data) {
        return (
            // TODO: 차수 추가
            // <PageTemplate title={`${scheduleQuery.data?.title} - ${scheduleQuery.data?.order}차수`}>
            <PageTemplate title={`${scheduleQuery.data?.title}`}>
                {getPage()}
            </PageTemplate>
        )
    }

}
export default Application