import styled from "styled-components"
import mixins, { media } from "../../styles/mixins";
import { useRef, useState } from "react";

const Wrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: ${mixins.pxToVw(1200)};
    min-width: 1200px;
    height: ${mixins.pxToVh(462, 680)};
    min-height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 80px;
        background-color: #000;
        padding: 10px 15px 10px 30px;
        
        .title {
            ${mixins.fontStyle("20px", 600, "27.24px")};
            ${mixins.clampTextLine(3)}
            color: #fff;
            word-break: break-all;
        }

        button {
            flex-shrink: 0;
        }
    }

    ${media.mobile`
        width: ${mixins.pxToVw(320, 360)};
        min-width: 280px;

        .header {
            padding: 20px;
            height: 62px;
            .title {
                ${mixins.fontStyle("16px", "600", "21.8px")}
            }
        }
    `}
`;

const CloseBtn = styled.button`
    width: 40px;
    height: 40px;
    background-color: transparent;
    background-image: url(${process.env.PUBLIC_URL+"/image/btn_close_modal.svg"});
    background-size: contain;
    border: 0;
    
    ${media.mobile`
        background-size: 40px 40px;
    `}
`;
    
const Body = styled.div`
    background-color: #fff;
    flex: 1 1;
    overflow: auto;
    padding: 50px;

    > img {
        display: block;
        width: 100%;
    }

    ${media.mobile`
        padding: 20px;
    `}
`

export default function Modal({title, content, show=false, hide=() => {}}) {
    const containerRef = useRef();
    return (
        show &&
        <Wrap onClick={(e) => {
            if (!containerRef.current.contains(e.target)) {
                hide();
            }
        }}>
            <Container ref={containerRef}>
                {/* <CloseBtn type="button" className="mo" onClick={() => {hide()}} /> */}

                <div className="header">
                    <div className="title">
                        {title}
                    </div>

                    <CloseBtn type="button" onClick={() => {hide()}} />
                </div>

                <Body>
                    {content}
                </Body>
            </Container>
        </Wrap>
    )
}