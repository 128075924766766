import { useQuery } from "@tanstack/react-query";
import api from "../../utils/api"

const fetchSchedule = (reqData) => {
    const eventIdx = reqData.queryKey[1];
    return api({
        url: "/schedule/" + eventIdx
    }).then((data) => data.data);
}

export const useScheduleQuery = (eventIdx) => {
    return useQuery({
        retry: 0,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryKey: ["schedule", eventIdx],
        queryFn: fetchSchedule,
        select: (data) => {
            return data.data;
        },
    });
};