import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: false,
    msg: "",
    btnText: "확인",
    func: () => {}
};
export const alertSlice = createSlice({
    name: "alert",
    initialState: initialState,
    reducers: {
        showAlert: (state, action) => {
            state.value = true;
            state.msg = action.payload.msg;
            if (action.payload.btnText) {
                state.btnText = action.payload.btnText;
            } else {
                state.btnText = initialState.btnText;
            }
            if (action.payload.func) {
                state.func = action.payload.func;
            } else {
                state.func = initialState.func;
            }
        },
        hideAlert: state => {
            state.value = false;
            state.onClickBtn = false;
        },
    }
})

export const {showAlert, hideAlert} = alertSlice.actions;
export default alertSlice.reducer;